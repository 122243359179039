<template>

  <jl-header v-if="header_show" />
  <audio hidden controls loop ref="audioMessage" id="audioMessage" src="//joylivecdn.oss-cn-shanghai.aliyuncs.com/mp3/12488.mp3
" />
  <router-view v-slot="{ Component }">
    <keep-alive :include="keepAliveList">
      <component :is="Component" @public_header="getMessage" />
    </keep-alive>
    <!-- <component :is="Component" v-if="!$route.meta.keepAlive" @public_header="getMessage" /> -->
  </router-view>
  <!-- <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath" @public_header="getMessage" /> -->
  <jl-footer v-if="header_show" />
  <el-dialog :title="告警" v-model="dialogVisible2" width="60%" :before-close="handleClose" destroy-on-close>
    <div
      style="width: 100%;height: 40px; border: 1px solid #CCC; display: flex; justify-content: space-between; align-items: center;">
      <div style="margin-left: 40px;">{{ GLF.warningType }}告警</div>
      <!-- <div style="color: red; margin-right: 40px ">204个告警信息</div> -->
    </div>
    <div style="display: flex;">
      <div style="width: 50%; margin-top: 10px;">
        <div
          style="width: 100%; height: 60px; background-color: red; color: #FFF; font-size: 30px; text-align: center; line-height: 60px;">
          {{ GLF.warningType }}告警
        </div>
        <div style="width: 100%; background-color: #CCC;">
          <div v-for="(item, index) in ableList" :key="index" style="padding-top: 10px; padding-left: 10px;">{{
    item.name }}:{{ elevatorDetail[item.no] }}
          </div>
          <div style="display: flex; margin-top: 20px;">
            <el-button v-if="GLF.warningType == '煤气罐'" style="background-color: red; border: none;" type="info"
              size="large" @click="handleClick('1')">误报</el-button>
            <el-button v-if="GLF.warningType == '电瓶车'" style="background-color: #3399FF; border: none;" type="info"
              size="large" @click="handleClick('2')">物业放行</el-button>
            <el-button v-if="GLF.warningType == '煤气罐'"
              style="margin-left: 10px;background-color: #00FF00; border: none;" type="info" size="large"
              @click="handleClick('3')">推送</el-button>
            <el-button style="margin-left: 10px;background-color: #3399FF; border: none;" type="info" size="large"
              @click="handleClick('4')">已读所有告警</el-button>
          </div>
        </div>

      </div>
      <div class="root" style="width: 100%; height: 440px; margin-top: 10px; margin-left: 20px;">
        <VideoPlay :othersId="othersIdGlf" :accessToken="accessTokenGlf" />
        <div>
          <div style="display: flex;">
            <!-- <el-button type="info" size="large">刷新</el-button> -->
            <!-- <el-button style="margin-left: 10px;" type="info" size="large">查看回放</el-button> -->
          </div>
          <div style="margin-top: 10px;">
            遇到视频加载异常情况，请查看以下可能原因进行记录分析
          </div>
          <div>
            1.回放找不到录像文件:摄像头内SD卡存在问题。
          </div>
          <div>
            2.设备推流链路网络不稳定:摄像头网络不稳定,请点击刷新重试。
          </div>
          <div>
            3.Data error;请求萤石云发生错误请点击刷新重试。
          </div>
          <div>
            4.设备资源受限:当前有用户正在查看回放,无法同时观看,请稍后点击刷新重试,
          </div>
          <div>
            5.视频黑屏:当前客户端请求黄石云画面超时或失败,请点击刷新或稍后重试。
          </div>
          <div>
            6.该用户不拥有该设备:该电梯未在萤石云账户注册,需在萤石云后台注册该摄像头.
          </div>
        </div>
      </div>
    </div>

  </el-dialog>
</template>
<script>
import { ElNotification } from "element-plus";
import { ref, reactive, toRefs, watch, getCurrentInstance, onMounted, watchEffect } from "vue";
import jlHeader from "./components/head";
import jlFooter from "./components/foot";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import VideoPlay from "@/views/VideoPlay.vue"
// import { useRoute } from "vue-router";
import { useRoute, useRouter } from "vue-router";
import protobuf from 'protobufjs';
import protoRoot from "@/proto.js";
import { file } from "@babel/types";
import { useI18n } from "vue-i18n";
// let root =await load("notify.proto",{keepCase: true});
export default {
  name: "App",
  components: {
    jlHeader,
    jlFooter,
    VideoPlay
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const state = reactive({
      ableList: [{ name: '物业名称', no: 'ownerCompanyName' }, { name: '物业联系人', no: 'ownerManager' }, { name: '物业联系电话', no: 'dutyPhone' },
      { name: '物业地址', no: 'address' }, { name: '维保名称', no: 'maintainerName' }, { name: '维保联系人', no: 'maintenanceManager' }, { name: '维保联系电话', no: 'phone' },
      { name: '告警时间', no: 'faultTime' }, { name: '所在小区', no: '' }, { name: '小区地址', no: '' }, { name: '电梯描述', no: '' }, { name: '备注信息', no: '' },],
      dialogVisible2: false,
      getToken: localStorage.getItem("token"),
      header_show: false,
      stompClient: "",
      timer: "",
      message: localStorage.getItem("message") || {},
      messageList: JSON.parse(localStorage.getItem("messageList")) || [], // 消息列表 只保存当前登录下的消息
      keepAliveList: [], // 缓存的页面
      GLF: {},
      elevatorDetail: {},
      accessTokenGlf: null,
      count: 0,
      othersIdGlf: 'ezopen://open.ys7.com/33011000992297770709:33011038991327052175/1.live',
    });
    window['playMp3'] = () => {
      var ttsAudio = document.getElementById("audioMessage");
      ttsAudio.play();
    }
    window['pauseMp3'] = () => {
      var ttsAudio = document.getElementById("audioMessage");
      ttsAudio.pause();
    }

    onMounted(() => {
      initSocketService()
      setInterval(() => {
        proxy.$api.system.getElevatorDetail(state.GLF.deviceId);
      }, 1000 * 60 * 10)
    })
    watchEffect(() => {
      if (route.path != '/' && route.path != '/login' && state.count < 1 && !state.getToken) {
        state.count++
        initSocketService()
      } else if (route.path == '/' || route.path != '/login' || state.count >= 1 || !state.getToken) {
        state.count = 0
        console.log(state.count, "state.count");
      }
    });
    watch(
      () => route.path,
      () => {
        let index = state.keepAliveList.findIndex(
          (item) => item === route.name
        );
        if (route.meta.keepAlive && index == -1) {
          state.keepAliveList.push(route.name);
        } else if (!route.meta.keepAlive && index > -1) {
          state.keepAliveList.splice(index, 1);
        }
        // playMp3();
        initData(route.path);
      }, { deep: true }
    );
    const initData = (o) => {
      if (o == "/welcome" || o == "/common/login") {
        // 手机扫描/登录页面清除长连接
        if (state.stompClient.connected) {
          state.disconnect();
          clearInterval(state.timer);
        }
      } else {
        // getMsg();
        if (!state.stompClient.connected) {
          setTimeout(() => {
            connection();
          }, 2000);
        }
      }
    };

    const connection = () => {
      if (
        proxy.$defined.btnPermission("消息推送") &&
        localStorage.getItem("token")
      ) {
        // 登录状态，有消息推送权限
        // 建立连接对象
        // let socket = new SockJS(
        //   "http://10.1.2.35:8021/websocket"
        // );
        let socket = new SockJS(
          // "https://iot.joylive.com/joycloud-pop/websocket"
          "http://106.14.36.215:31021/websocket"
        );
        // 获取STOMP子协议的客户端对象
        state.stompClient = Stomp.over(socket);
        // 定义客户端的认证信息,按需求配置
        let headers = {
          Authorization: localStorage.getItem("token"),
        };
        // 向服务器发起websocket连接
        state.stompClient.connect(
          headers,
          () => {
            state.stompClient.subscribe(
              `/queue/fault/${localStorage.getItem("username")}`,
              (msg) => {

                // 订阅服务端提供的某个topic
                let Notify = protoRoot.lookupType("Notify");
                const rs = window.atob(msg.body);

                // console.log(rs);
                const fileReader = new FileReader();
                // fileReader.readAsArrayBuffer(rs);
                let blob = new Blob([rs], { type: 'text/plain;charset=utf-8' });
                fileReader.readAsArrayBuffer(blob);

                fileReader.onload = function (e) {
                  // console.log(e.target.result);
                  const u8 = new Uint8Array(e.target.result);
                  const data = Notify.decode(u8);
                  console.log(data);
                  state.message = data;
                  state.messageList.push(data);
                  localStorage.setItem("message", data);
                  localStorage.setItem(
                    "messageList",
                    JSON.stringify(state.messageList)
                  );
                  getMsg(); // msg.body存放的是服务端发送给我们的信息
                }




                // console.log(data.Notify.content);
                // load("NotifyDto.proto").then((err,root)=>{
                //   console.log("root",root);
                //   console.log("error",err);
                //   const MyMessageType = root.lookupType("com.joylive.iot.ws.domain.MessageDto");
                //   const decodeData = MyMessageType.decode(msg.data);
                //   let data = JSON.stringify(decodeData);
                //   console.log(data);

                // })

              },
              headers
            );
            state.stompClient.send(
              "/app/hello",
              headers,
              JSON.stringify({ sender: "", chatType: "JOIN", msg: "测试" })
            );
            // 用户加入接口
          },
          (err) => {
            // 连接发生错误时的处理函数
            console.log("失败");
            console.log(err);
            // setTimeout(() => {
            //     state.connection();
            // },2000)
          }
        );
      } else {
        disconnect();
      }
    };
    const maintenanceApi = (id, result) => {
      let data = proxy.$api.maintenance.dealAlarm(id, result)
      console.log(data.data.success);
      if (data.data.success) {
        state.dialogVisible2 = false
      }
    }
    const handleClick = (e) => {
      console.log(e);
      switch (e) {
        case '1':
          // maintenanceApi(1,'误报解除',2)/
          maintenanceApi(state.GLF.id, '自动解除',)
          break;
        case '2':
          maintenanceApi(state.GLF.id, '物业放行')
          break;
        case '3':
          maintenanceApi(state.GLF.id, '已推送')
          // result = '已推送'
          break;
        case '4':
          state.dialogVisible2 = false
          break;
        default:
          break;
      }
    }
    const disconnect = () => {
      // 断开连接
      if (state.stompClient) {
        state.stompClient.disconnect();
      }
    };
    const initSocketService = () => {
      console.log((['wujun01', 'shangjing'].includes(localStorage.getItem("username"))), "shjhsajasjh");
      if (localStorage.getItem("token") && localStorage.getItem("username") == 'GLF') {
        var url = "https://iot.joylive.com/joycloud-pop/websocket"
        var sockjs = new SockJS(url);
        var stompClient1 = Stomp.over(sockjs);
        stompClient1.heartbeat.outgoing = 20000;
        stompClient1.heartbeat.imcoming = 10000;
        var header = {
          "Authorization": localStorage.getItem("token"),
        }
        console.log(header);
        stompClient1.connect(header, function connectCallback(frame) {
          console.log(frame, 'frameframe');
          stompClient1.subscribe('/glf/GLF', function (response) {
            if (!state.dialogVisible2) {
              state.GLF = JSON.parse(response.body)
              console.log("获取成功广联发", state.GLF);
              if (state.GLF.warningType) {
                handleMessage(state.GLF.deviceId)
              }
            }
          });
        },
          function errorCallBack(error) {
            setTimeout(() => {
              initSocketService()
            }, 5000)
            if (error.headers.message.includes('未认证，请重新登录')) {
              localStorage.removeItem('token');
              localStorage.removeItem('userId');
              localStorage.removeItem('username');
              localStorage.removeItem('other');
              localStorage.removeItem('MIAO_LID');
              localStorage.removeItem('SECKEY_ABVK');
              localStorage.removeItem('menu');
              localStorage.removeItem('query');
              router.push('/login?flag=0');

            }
            console.log(error, 'errorerror');
          }
        );
      } else if (localStorage.getItem("token") && (['wujun01', 'shangjing'].includes(localStorage.getItem("username")))) {
        var url = "https://iot.joylive.com/joycloud-pop/websocket"
        // const url = "http://10.1.2.35:8021/websocket";
        var sockjs = new SockJS(url);
        var stompClient1 = Stomp.over(sockjs);
        stompClient1.heartbeat.outgoing = 20000;
        stompClient1.heartbeat.imcoming = 10000;
        var header = {
          "Authorization": localStorage.getItem("token"),
          // "Authorization": 'sdhjfgbjf',

        }
        console.log(header);
        stompClient1.connect(header, function connectCallback(frame) {
          console.log(frame, 'frameframe');
          stompClient1.subscribe('/glf/wujun01', function (response) {
            if (!state.dialogVisible2) {
              state.GLF = JSON.parse(response.body)
              console.log("获取木渎数据", state.GLF);
              if (state.GLF.warningType) {
                handleMessage(state.GLF.deviceId)
              }
            }
          });
        },
          function errorCallBack(error) {
            setTimeout(() => {
              initSocketService()
            }, 5000)
            console.log(error.headers.message, 'errorerror');
            if (error.headers.message.includes('未认证，请重新登录')) {
              localStorage.removeItem('token');
              localStorage.removeItem('userId');
              localStorage.removeItem('username');
              localStorage.removeItem('other');
              localStorage.removeItem('MIAO_LID');
              localStorage.removeItem('SECKEY_ABVK');
              localStorage.removeItem('menu');
              localStorage.removeItem('query');
              router.push('/login?flag=0');

            }
          }
        );
      }

    }
    const handleMessage = async (deviceId) => {
      let { data } = await proxy.$api.system.getElevatorDetail(deviceId);
      if (localStorage.getItem("username") == 'GLF') {
        let item = await proxy.$api.common.getGlfAccToken();
        state.accessTokenGlf = item.data.data.accessToken
      } else {
        let item = await proxy.$api.common.getAccToken();
        state.accessTokenGlf = item.data.data.accessToken
      }
      // let item = await proxy.$api.common.getGlfAccToken();
      // state.accessTokenGlf = item.data.data.accessToken
      state.elevatorDetail = data
      state.othersIdGlf = data.othersId
      setTimeout(() => {
        state.dialogVisible2 = true
      }, 200)
    }
    const getMsg = () => {
      // 消息提醒
      // ElNotification.closeAll();
      if (state.header_show && localStorage.getItem("token")) {
        if (state.message != undefined) {
          playMp3();
          var html =
            "<p class='title'>故障提示：<span>" +
            state.messageList.length +
            "</span>条未处理</p><div class='flex'>" +
            "<div class='list'>";
          // for (var i = 0; i < (state.messageList.length<=5?state.messageList.length:5); i++) {
          //     // console.log(state.messageList[i]);
          //   html +=
          //     "<div class='item'><p>" +
          //     parseInt(i + 1) +
          //     "," +
          //     state.messageList[i].elevatorNumber +
          //     "电梯于" +
          //     state.messageList[i].faultTime +
          //     "时间报" +
          //     state.messageList[i].content +
          //     "故障，请立刻处置！</p><el-button plain onclick='dealFaultMsg("+

          //     "\""+
          //     state.messageList[i].id+
          //      "\",\""+
          //     state.messageList[i].url+
          //     "\")'>" +
          //     t("i18n.view") +
          //     "</el-button>";
          //     ElNotification({
          //       type: 'warning',
          //       position: "bottom-right",
          //       offset: 40,
          //       duration: 0,
          //       title: "普通故障",
          //       customClass: "workMessage",
          //       // dangerouslyUseHTMLString: true,
          //       message: state.messageList[i].elevatorNumber +
          //               "电梯于" +
          //               state.messageList[i].faultTime +
          //               "时间报" +
          //               state.messageList[i].content +
          //               "故障，请立刻处置！",
          //       onClose() {
          //         // 关闭通知，清除数据
          //         localStorage.removeItem("messageList");
          //       },
          // });

          // }
          ElNotification({
            type: 'warning',
            position: "bottom-right",
            offset: 40,
            duration: 0,
            title: "普通故障",
            customClass: "workMessage",
            // dangerouslyUseHTMLString: true,
            message: state.message.elevatorNumber +
              "电梯于" +
              state.message.faultTime +
              "时间报" +
              state.message.content +
              "故障，请立刻处置！",
            onClose() {
              // 关闭通知，清除数据
              pauseMp3();
              localStorage.removeItem("message");
            },
            onClick() {

              console.log("dfsdfasdfa");
              let routeData = router.resolve({
                path: "/realTime/detail?id=",
                query: { flag: 1, id: state.message.url },
              });
              window.open(routeData.href, "_blank");
              this.close();
            }
          });
          html += "</div></div></div>";

          // window.dealFaultMsg = state.dealFaultMsg;
        }
      }
    };

    const getMessage = (val) => {
      console.log(val, "Valvalvalvlavlal");
      state.header_show = val === true;
    };


    return {
      handleClick,
      initSocketService,
      ...toRefs(state),
      getMessage,
    };
  },
};
</script>
<style lang="scss">
#productDistribution {
  .el-tabs {
    position: absolute;
    width: 4 * $width + 20;
    z-index: 99;
    top: 30px;
    background: $white;
    left: 31px;
    font-size: $font - 2;
    box-shadow: 0px 3px 10px 0px rgba(30, 111, 72, 0.35);

    .el-tabs__content {
      padding: 0;
    }

    .el-tabs__content {
      max-height: 280px;
    }

    .list {
      padding: 5px;
      height: 250px;
      overflow-y: auto;

      .index,
      .item {
        display: inline-block;
        vertical-align: top;
      }

      .active {
        background: rgba(204, 204, 204, 0.5);
        color: #333;
      }

      >div {
        padding: 10px;
        cursor: pointer;
      }

      .item {
        padding-left: 10px;
      }
    }
  }

  .redDot,
  .yellowDot {
    width: 10px;
    height: 10px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
  }

  .redDot {
    background: #d02c2f;
    animation: scale 2s infinite;
  }

  .yellowDot {
    background: #fdbf01;
  }

  @keyframes scale {
    @for $i from 0 through 10 {
      #{$i * 10%} {
        transform: scale($i / 10);
        opacity: $i / 10;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #productDistribution {
    margin-top: -20px;

    .el-tabs {
      top: 10px;
      left: 10px;
      width: 300px;
    }
  }
}
</style>