/* eslint-disable */
module.exports = {
  copyright: "Copyright  © 2016-2024 Joylive  iot.joylive.com  All Rights Reserved. Case No: 苏ICP备14058511号-2",
  cancel: "Cancel",
  onlineNum:"onlineNum",
  todayFault:"todayFault",
  tips:"tips",
  brandManage: "Elevator brand management",
  totalAmountRO: "Total amount of repair orders(RO)",
  standbyHours: "Standby hours",
  year: "Year",
  newMaintenanceBatches: "New maintenance batch",
  stopOnFloor: "Which floor level lift down with trap case incident",
  illegalCharactersInNineNo: "Illegal characters in 96333 No",
  approved: "Approved",
  hoursRO: "Number of repair hours",
  usedNotDelete: "You can not delete it as it has been used.",
  poor: "Poor",
  distanceLengthForUrgentRepair: "Distance travel for urgent repair(km)",
  setUp: "Set up",
  videoPlayback4: "4G Video playback",
  excellent: "Excellent",
  paidMaintenance: "Paid maintenance",
  totalQualifiedRateQDMC: "The total passing rate for QDMC",
  selectCompany: "Please select company",
  entertainmentPlace: "Entertainment place",
  signOut: "Log out",
  maintenanceContract: "Maintenance contract",
  version: "version",
  overload: "Overload",
  airport: "Airport",
  billingRecord: "Billing record management",
  elevatorInfo: "Elevator information",
  newPaymentInfo: "New payment record",
  statusOffline: "Elevator status: Offline ",
  fillLoseReason: "Please fill in the reason for the loss",
  reserved: "Reserved",
  unfinishedOrderTotal: "Total number of unfinished work order(To be accepted,Processing,Paused)",
  malfunctionNature: "Fault nature",
  responseTO: "Average response time of TO(min)",
  rescuerQualificationManagement: "Rescuer qualification management",
  upPassingChime: "Up passing chime",
  role: "Role",
  savePlanRemark: "Save the schedule plan",
  deliveryTime: "Due time",
  inspectionTerms: "Inspection terms",
  historyRoute: "History route",
  viewEleInfo: "View elevator information",
  maintenanceBatch: "Maintenance batches",
  notHaveTop: "Reference company does not create a top level group",
  planExpiring: "Expiring maintenance plan list",
  qualifiedRateNEMC: "Qualified rate of NEMC",
  PASSED: "Passed",
  normalAnnual: "Normal Annual Inspection",
  result: "Result",
  fillInReason: "Please select overdue reason",
  to612Months: "6-12 months",
  hoursTO: "Number of trapped hours",
  companyNotHaveTop: "The company does not create a top level group",
  canNotUpdateMaintenanceStaff: "Unable to update the maintenance staff",
  servicePlatformUnitsList: "Service platform units list",
  thirdFaultLevel: "Third fault level",
  selectInspectStatus: "Please select the outcome of inspection",
  statusMalfunction: "Elevator status: Error ",
  close: "Close",
  resolved: "Resolved",
  lastMaintenanceDate: "Last maintenance date",
  address: "Address",
  mainSchedule: "Maintenance condition chart",
  locationContrast: "Location contrast",
  qualifiedQDMC: "Qualified QDMC",
  sNTwo: "Sale outlet two",
  servicePlatformData: "Service platform data report",
  statusRecondition: "Elevator status: Maintenance ",
  companyData: "Company data",
  kunshanNo: "Kunshan 119 number",
  commissionMaintenanceUnitsFree: "Commission maintenance units(free) ",
  renewLostInfo: "Manage renewal, lost, billing, receipt and other information",
  SUSPEND: "Paused",
  cloudPush: "Dispatch urgent message(SMS)",
  doorOpenMode: "Door open mode",
  youCanNotDeleteElevator: "You can not delete the elevator which is in use by some companies",
  responseRO: "Average response time of RO(min)",
  openDoorBtnShow: "Door open button show",
  putAway: "Put away",
  staffInfo: "EmployeeInformation",
  excellentOrderTotal: "Total number of excellent work order",
  quarterM: "Quarterly",
  keywords: "Key words",
  doorOpen: "Door open",
  lastAnnualInspectionDate: "Annual inspection date",
  qrCodeLogo: "Qr code logo",
  factoryNo: "Factory no",
  intelligenceCode: "Registered intelligent hardware code",
  good: "Good",
  averageResponse: "Average response(min)",
  roleNameContainType: "The reputation of the company to be contain",
  notEvaluatedOrderTotal: "Total number of not-evaluated work order",
  replacements: "Replacements",
  tractionFreight: "Traction drive freight elevator",
  export: "Export",
  ele: "Elevator",
  shouldNotBeGreaterThan: "amount receive should be less than the invoice No",
  area: "Maintenance area",
  trappedFault: "Trapped fault",
  maintenanceUnits: "Maintenance units",
  elevatorNo: "Elevator no",
  carTopInput: "Car top input state",
  totalAmountMO: "Total amount of maintenance orders(MO)",
  noMatchingElevatorFound: "No matching elevator found",
  oldPassword: "Old password",
  chooseScanCode: "Please choose whether to scan QR code",
  invoiceIssuerContact: "Contact Person (Invoice Issuer)",
  finishedAI: "Overdue Finish",
  editElevatorBrand: "Edit elevator brand",
  faultCode: "Error code",
  videoMonitor: "Video monitor surveillance",
  codeLocManage: "Location of QR code Management",
  success: "Successfully",
  actualCompletionDate: "Actual completed date",
  nextStep: "Next step",
  maintenanceHeadCount: "Maintenance head count",
  unsolved: "Unsolved",
  followUpWorkOrders: "Follow-up repair work orders",
  halfAYear: "Semi-yearly maintenance",
  aiStatus: "Annual Inspection Status",
  workOrderManagement: "Work order management",
  workOrderInquiry: "Work Order Inquiry",
  expiryDate: "Expiry date",
  totalHoursForMaintenance: "Total hours for maintenance(hour)",
  statusAll: "Elevator status: All ",
  maintenanceCurrentPerson: "Current Maintenance staff",
  generalOrder: "General order",
  manufactureDate: "Date of manufacture",
  selectContract: "Please select the contracts",
  end: "End",
  resendAfter: "Resend after",
  lostTrustee: "Lost trustee",
  useMode: "Do you want to use the mode of Joylive?",
  ObjectDoesNotExist: "The object does not exist",
  pThree: "Product three",
  companyType: "Type of company",
  paymentMode: "Payment method",
  completeCD: "Complete contract database",
  alternativeStaff: "Alternative staff name",
  scanCode: "Scan QR code",
  collectionRecord: "Collection record management",
  addContract: "Add maintenance contract",
  contractNoExists: "Contract number already exists ",
  satisfactionEvaluation: "Satisfaction evaluation",
  trappedBeing: "Assignment in progress",
  elevatorStatus: "Elevator status",
  selectLast: "Please select last annual inspection date",
  AgedKidsPregnantInCar: "Were there handicapped, elderly, children, pregnant women trapped in the lift car?",
  inOutTerminalState: "Main control board input/output terminal status",
  shaft: "Shaft",
  shootVideo:"shootVideo",
  numberOfVideo:"numberOfVideo",
  UnifyTheSocialCreditCode:"UnifyTheSocialCreditCode",
  enterOrder: "Enter work order",
  nextAnnualInspectionDate: "Next annual inspection date",
  noMatchingScanPosition: "No matching scan position",
  statusInfo: "Status Info",
  UidNotExist: "Id does not exist",
  elevatorFile: "Elevator file",
  problemSolvingResponseTime: "Problem-resolve response time(min)",
  number: "Number",
  fileNotMatch: "The file does not comply with the requirements",
  directSignal: "Direct signal",
  carTopOutput: "Car top output state",
  videoChat: "Video call",
  workBillPush: "Sent urgent case work order",
  organizationalStructure: "Organizational structure",
  order: "Order",
  automaticSidewalk: "Automatic sidewalk",
  period: "period",
  consignee: "Consignee",
  maintenanceLevelInfo: "Maintenance rating level info",
  outOfTextLength: "No more than 10 Chinese characters",
  contractsInfo2: "ContractsInformation",
  tiltAngle: "Tilt angle",
  contentNotEmpty: "Please fill in item content",
  invoiceNoExists: "The invoice No. already exists",
  theAmountCollected: "The amount collected",
  insReport: "Self-inspection report",
  generationTime: "Generate time",
  sceneDescription: "Site description",
  mobilePhone: "Mobile phone",
  inspectionQualifiedUnits: "Official inspection qualified units",
  repairHeadCount: "Repair head count",
  statusIncorrect: "Inaccurate status",
  ACCOMPLISH: "Completed",
  elevatorDis: "Elevator distribution statistics",
  loginFails: "Login failed.",
  elevatorEvent: "Elevator event",
  other: "Other",
  addElevator: "New elevator",
  city: "City",
  save: "Save",
  login: "Login",
  newDue: "Added due",
  valid: "Validity",
  maintenanceUnitsChange: "Changes in maintenance number",
  deletedUnsuccessful: "Deleted failed",
  confirmPassword: "Confirm password",
  validateContract: "Validate contract($SGD/monthly)",
  joylive: "Joylive",
  contractInformation: "Contact information",
  areaManager: "Regional Manager",
  totalAmountCFO: "Total amount of customer feedback orders(CFO)",
  product: "Product",
  invalidStatus: "Invalid status",
  usernameNotExist: "Username does not exist",
  mobileIncorrect: "Please fill in right mobile,format:158XXXXXXXX ",
  relatedIssues: "Issuer",
  staffNoAuthority: "The staff has no authority to maintain the elevator.",
  unqualifiedSMC: "Total failure number for SMC",
  hoursMO: "Number of maintenance hours",
  to12Years: "1-2 years",
  operationNode: "Operation node",
  otherMonth: "Other months",
  institutionsBuilding: "Institutions building",
  sNThree: "Sale outlet three",
  contractAwardUnits: "Contract award units",
  iOSApp: "iOS app",
  importedFailed: "Imported failed",
  ModeJoylive: "Mode of Joylive",
  dueFreeMaintenance: "Due free-maintenance",
  unitYuan: "unit:yuan",
  effectiveDate: "Contract effective date",
  residentialBuilding: "Residential building",
  aRepairRO: "Average repair time of RO(min)",
  chooseBrand: "Selected a brand",
  faultRate: "Fault rate",
  everyPageShow: "Per page shows",
  enterCompanyName: "Please enter company name",
  verifyUser: "Verify user",
  statusReport: "Status report",
  newRepairRecord: "New repair record",
  managerNotSame: "Manager and the staff account can not be the same",
  noUseFunction: "You has no authority to use the function",
  selectMaintenanceLevelInfo: "Please select maintenance level",
  anyoneInjured: "Was there anyone injured?",
  maintenanceCompany: "Maintenance company",
  ignoredFaultRecord: "Ignored fault record",
  unqualifiedSMCIn: "Unqualified SMC in a department",
  totalHoursOfUrgentRepair: "Total hours of urgent repair(hour)",
  maintenanceType: "Maintenance type",
  elevatorMonitoringDetails: "Elevator monitoring details",
  userFail: "Failed to get info of current user.",
  chooseRecordOperational: "Please choose whether to record operational process",
  longitude: "Longitude",
  overhaulPerformed: "Overhaul performed",
  reassignOrder: "Reassign work order",
  modifyOrder: "Modify work order",
  all: "All",
  elevatorModel: "Elevator model",
  new: "New",
  thisBrandExists: "This brand exists",
  progressReport: "Progress report",
  renewalStatus: "Renewal status",
  materialName: "Material Name",
  totalAmount: "Total",
  halfMonth: "Semi-monthly maintenance",
  unit: "Units",
  runningTimes: "Running times",
  groupHas: "The company already has top level group",
  maintenanceTotalUnits: "Total number of Maintenance",
  violation: "violation",
  lostManage: "Lost management",
  totalQDMC: "Total units of Quality Department Maintenance Check(QDMC)",
  contractAmount: "Contract amount",
  exposureEvent: "Exposure event",
  renewPaidMaintenance: "Renew paid-maintenance",
  doYouWantToIgnore: "Do you really want to ignore?",
  lastPageText: "Last page",
  annualVolumeStatistics: "Annual statistics of units",
  unitsSMC: "Check the total amount of self-maintenance check (SMC)",
  accountReceivable: "Accounts receivable",
  basData: "basic data",
  accountName: "Account name",
  companySlogan: "Company slogan",
  totalQualifiedRateNEMC: "The total passing rate of the new elevator (NEMC) check",
  partOfContractLost: "Part of the contract that was lost",
  qualifiedNEMC: "Qualified NEMC",
  otherStaff: "Other maintenance staff",
  energySaving: "energy-saving",
  units: "Unit",
  personInfo: "Management maintenance personnel number, name, status, number of work orders and evaluation.",
  enterBillingDate: "Please enter billing date",
  times: "times",
  notSatisfied: "Unsatisfied",
  removeEleNo: "You has no authority to remove elevators of the group",
  today: "Today",
  elevatorBrand: "Elevator brand",
  userNoPer: "Sorry, you do not have permission to enter.",
  department: "Department",
  monthlyStatistics: "Monthly statistics of work order type",
  picturesNumberLess: "The number of pictures should be less than 5",
  vehicleMilesTravelT: "Vehicle mileage of travel(trapped)",
  floorsNum: "Number of floors",
  selectElevatorsYouNeed: "Please select elevators you need",
  serviceStaff: "Attended reporting personnel",
  reportAnalysis: "Report analysis",
  drawer: "Withdrawer",
  yearEnd: "Annual maintenance",
  vehicleMilesTravelM: "Vehicle mileage of travel(maintenance)",
  staffNameExists: "Staff name already exists",
  caseTwo: "Case two",
  optimumSize: "width:30-107 pixels,height:30-45 pixels",
  urgentRepairManHour: "Urgent repair Labour-hour",
  repairTwo: "Repair",
  eleListExpiringDate: "Elevators list(expiring annual inspection date)",
  managerMu: "Manager Mr. Mu",
  theNumberOfTimesVerify: "What is the number of times to do next semimonthly maintenance should be between 1-24",
  dataOverview: "Data Overview",
  signIn: "Log in",
  maintenance: "Maintenance",
  maintenanceContractNo: "Contract no",
  relevantCompany: "Relevant company",
  aResponseTO: "Average response time of TO(min)",
  reason: "Reason",
  generalVATInvoice: "General VAT Invoice",
  maintain: "Maintenance",
  paidMaintenanceUnits: "Paid-maintenance number",
  CollectionDateIsNotRight: "Collection date is incorrect",
  eleCanNotDelete: "You can not delete the elevator that has been generated records",
  enterReceiptDays: "Please enter collection date",
  rectify: "Rectify",
  resolvedPartly: "Partly resolved",
  password: "Password",
  enterStaffName: "Please enter staff name",
  trappedNumber: "Number of people trapped",
  jan: "Jan.",
  buzzerOutput: "Buzzer output",
  subcontractCompany: "Subcontractor company",
  fillInEndTime: "Please fill in end time",
  downPassingChime: "Down passing chime",
  lightCurtain: "Light curtains",
  fillTag: "Please fill in tag of QR code",
  selfMaintenanceUnits: "Self-maintenance number",
  partialRenew: "Partially Renew",
  apr: "Apr.",
  accessoriesNumber: "Accessories Number",
  handleOrder: "Handle the order",
  androidApp: "android app",
  controllerType: "Controller type",
  youCanNotDeleteContract: "You can not delete this contract which is in use by elevators",
  inspectPage: "Inspect page",
  enterInvoiceNo: "Please enter invoice No",
  doYouWantToRemove: "Do you really want to remove?",
  noRelevantData: "No relevant data",
  productDistribution: "Product distribution",
  groupName: "Group name",
  installationExecution: "Installation contract execution",
  exposureTable: "Exposure Table",
  terminalValid: "Terminal is valid",
  timeBeforeToday: "The time should not be before today.",
  totalPageBeforeText: "Total&nbsp;",
  totalHoursMRO: "Total hours of maintenance and repair orders",
  maintenanceService: "Maintenance service",
  noStats: "No relevant data. Please choose another time period or region",
  fillInEleNum: "Please fill in elevator number",
  unqualifiedNEMC: "Unqualified NEMC",
  invoiceAmountGreaterThan: "Invoice amount should be greater than zero and format is 0.00.",
  selfMaintenanceUnitsFree: "Self maintenance units(free)",
  basicData: "Basic data",
  legalOrg: "Legal or other organization",
  totalQualifiedNEMC: "The total number of new elevator that Pass the (NEMC) check",
  faultHistory: "Fault History",
  selectOrderType: "Please select work order type",
  selectElevatorType: "Please select elevator type",
  takeSteps: "Action taken",
  nominalWidth: "Nominal width",
  staffName: "Staff name",
  comLogo: "Company logo",
  othersReason: "Others reason",
  putAwayFilter: "Put away filter",
  downloadTemplate: "Download template",
  staffManagement: "Staff management",
  upCall: "Up call",
  dataSynchronization: "Data synchronization ",
  refresh: "Refresh",
  clickForDetails: "click for details",
  updateLog: "Update Log",
  howManyDaysToComplete: "The number of days required to complete",
  efficiencyReport: "Efficiency report",
  invoiceDeliveryAdd: "Invoice Delivery Address",
  invoiceIssuerPhone: "Invoice Contact Number",
  qrOptimumSize: "width:30-1080 pixels,height:30-720 pixels",
  topLevel: "topLevel",
  totalPageAfterText: "&nbsp;pages",
  undetermined: "Undetermined",
  amountCFO: "Amount of CFO",
  anInManage: "Annual Inspection Management",
  amountOrders: "Number of work orders",
  endTime: "End Time",
  underConstruction: "The page is under construction, please wait",
  lostPaidMaintenance: "Lost paid-maintenance",
  monitorCheck: "Please choose whether to check video monitor",
  groupManagement: "Group management",
  exportPeriodIncorrect: "The export period is incorrect",
  functionCode: "Function code",
  upkeepContracts: "Contract",
  doYouWantToReuse: "Do you really want to reuse it again?",
  improperUse: "Customers improper use",
  eleListExpiring: "Elevators list",
  operator: "Operator",
  to36Months: "3-6 months",
  exportFilterInaccuracy: "Please correctly filter and export",
  totalUnitsSMC: "Total units of self maintenance check(SMC)",
  view: "View",
  repairOrderNumbers: "Completed repair work order numbers",
  school: "School",
  unfinishedRO: "Unfinished RO",
  certificatePhoto: "Certificate photo",
  dumbWaiter: "Dumb waiter",
  workOrder: "Work order",
  trapped: "Trapped",
  sNFour: "Sale outlet four",
  unclosed: "Unclosed",
  telephone: "Telephone number",
  serviceCenterIOT: "IoT platform data report(service center)",
  maintainCustomerEnvironmentSecurity: "Maintain customer environment and security",
  onSiteContactPerson: "Contact Person (On-site)",
  aug: "Aug.",
  selectAll: "Select all",
  changeAccountNot: "You can not change account which is already in use.",
  totalAmt: "Total Amount",
  amountTO: "Amount of TO",
  theNTimeOfMaintenance: "The N time of maintenance",
  remouldBilling: "Remould billing",
  name: "Name",
  faultTimes: "Times of malfunction",
  addParentElevators: "Add elevators of parent group",
  projectName: "Project name",
  lastStaff: "Last maintenance staff",
  relatedMaintenanceOrderNo: "Related Maintenance Contract Number",
  invalidRequest: "invalid request",
  aResponseRO: "Average response time of RO(min)",
  import: "Import",
  pwdFormat: "Password format: use more than 8 characters and it contains letter, numeral or characters.",
  prePageText: "Previous page",
  description: "Description",
  smsNumber: "SMS notification number",
  video: "video",
  maintenanceAttr: "Maintenance attribute",
  enterProjectName: "Please enter project name",
  resendCode: "Resend SMS check code",
  countInfo: "Statistics related contract information",
  productionDate: "Production date",
  todoAI: "To-do Annual Inspection",
  lableStyle: "In tag",
  nov: "Nov.",
  dynamicDetection: "Detection",
  accessories: "Accessories mall",
  manageContent: "Manage for work orders related",
  unfinishedOrders: "Unfinished work order",
  issueInvoice: "Issue Invoice",
  pendingMatters: "Pending matters",
  unfinishedTO: "Unfinished TO",
  brandData: "Elevator brand data",
  firstFaultLevel: "First fault level",
  naturalPerson: "Natural person",
  residenceName: "Residence name",
  approvalStatus: "Approval status",
  totalTrappedRate: "Total trapped rate",
  hours: "hours",
  selfInsFinish: "Self-inspection finish",
  yes: "Yes",
  numberOfPictures: "Number of pictures",
  selectParentGroup: "Please select parent group name",
  nodeTwo: "Section point two",
  remoteRealTime: "Real-time monitoring of elevator operation",
  distanceTimeConsumingForMaintenance: "Travel duration for maintenance(hour)",
  amountRO: "Amount of RO",
  softwareTheLatestVersion: "The software are update version",
  viewOrder: "View order",
  elevatorNoNotExists: "Elevator No does not exist",
  loseReason: "The reason for the loss",
  maintenanceVolumeStatistics: "Statistics of maintenance units",
  instantMessage: "Urgent information message",
  quotation: "Quotation",
  disNotRight: "The format of display floor number is incorrect.",
  finance: "Finance",
  orderType: "Type",
  anySearchInfo: "By entering keyword for selecting a different option, you can easily search for requite assistant.",
  totalHoursRO: "Total hours of RO",
  renewalNo: "Renewal contract number",
  refusedOrderTotal: "Total number of refused work order",
  importedSuccessfully: "Imported successfully",
  billing: "Billing($SGD/monthly)",
  beaconNo: "Beacon no",
  logout: "Log out",
  totalQualifiedSMC: "Total qualified of SMC",
  FAILED: "Failed",
  buildingNo: "Site building no",
  deliveryAddress: "Delivery Address",
  functionSelection: "Function selection",
  userInformation: "User information",
  techConsultation: "Technical Consultation",
  purchaseNo: "Purchase no",
  pushedFailed: "Deliver failed",
  manageBasicInfo: "Manage of maintenance basic contract information",
  sNFive: "Sale outlet five",
  statisticalEndDate: "Statistical end date",
  choosePerson: "To assign select staff",
  EVALUATION: "Evaluation needed",
  newPassword: "New password",
  completeRateRO: "RO complete rate",
  nominalSpeed: "Nominal speed",
  timingError: "Please enter the correct duration",
  projectAliasAlreadyExists: "Place of use already exists",
  validPictures: "Please fill in valid number of pictures",
  dangerTips: "Changing all content of this page will directly affect site logo posted and staff jobs, please operate carefully.",
  selectTips: "Please select the node library, otherwise the installation work order cannot be added",
  DONEREFUSE: "Rejection being process",
  recondition: "Overhaul",
  dynamicSupervision: "Dynamic Supervision",
  pendingIssuesOld: "Pending issues",
  complaintsHotline: "Complaints hot-line",
  reset: "Reset",
  physicalFloor: "Physical floor",
  commercialAndResidentialBuilding: "Commercial and residential building",
  selectGroupName: "Please select group name",
  moderateOrderTotal: "Total number of moderate work order",
  contractNo: "Contract No",
  selectNext: "Please select next annual inspection date",
  fullLoad: "full-load signal",
  doorCloseOutput: "Door close output",
  totalHoursTO: "Total hours of TO",
  setFilterButtons: "Key classification of elevator health status(set by filter button).",
  unknown: "Unknown",
  low: "low",
  maintenanceItemsConfiguration: "Maintenance items configuration",
  completeRateCFO: "CFO complete rate",
  startTime: "Start time",
  workOrderStatus: "Work order status",
  TOFIX: "Maintenance needed",
  onlineMonitoring: "Monitoring",
  contractNature: "The nature of the contract",
  maintenanceMonth: "Maintenance monthly",
  more: "More",
  staffAccount: "Staff account number",
  remouldPerformed: "Remould performed",
  numberFiveStar: "Satisfaction (5 stars)",
  roleEm: "Role can not be empty.",
  informationStatistics: "Information statistics",
  numberNotFiveStar: "Satisfaction (not 5 stars)",
  currentMaintenanceUnits: "Current Maintenance Number",
  receivedAmount: "Amount received($SGD)",
  newMaintenanceAccount: "New maintenance account",
  completeRateTO: "TO complete rate",
  loseContract: "Lost contract",
  newPaidMaintenance: "New paid-maintenance",
  importMaintenanceProgram: "Import maintenance program",
  staffState: "Employee status",
  distanceLengthForMaintenance: "Distance travel for maintenance(km)",
  companyAddress: "Company address",
  inspectReason: "Reason",
  sendApp: "APP successfully sent urgent message",
  multiBrandLift: "Multi-brand elevator",
  completeRateMO: "MO complete rate",
  noMatchingProjectNameFound: "No matching project name found",
  feb: "Feb.",
  displayFilter: "Display filter",
  searchTerms: "Did not find what you expected, please try other search terms, or call Joylive service hot-line:0512-36607152",
  num: "number",
  pTwo: "Product two",
  fillInSTime: "Please fill in start time",
  homeLift: "Home lift",
  homeLift1: "Home lift",
  deleteElevatorBrand: "You can not delete the elevator brand which already has been used",
  completeCFO: "Complete CFO",
  serviceAttitude: "Service attitude",
  contractsInfo: "Maintenance contracts information",
  qualifiedSMCIn: "Qualified SMC in a department",
  errorHappened: "Error happened",
  ratedLoad: "Rated load",
  forgotPwd: "Forgot password?",
  enterRightNum: "Please fill in the correct number, format:0",
  aLittlePoor: "A little poor",
  day: "(Day)",
  halfAYearM: "Semi-yearly",
  group: "Group",
  nodeThree: "Section point three",
  totalHoursMO: "Total hours of MO",
  specialVATInvoice: "Special VAT Invoice",
  created: "Created",
  minutes: "minutes",
  sendTwo: "Send",
  noRelatedStaff: "No relevant staff",
  faultTime: "Downtime failure",
  inspectStatus: "Inspection Status",
  editDate: "Edit annual inspection date",
  ACCEPT: "Accept",
  expiringReminder: "Expiring reminder",
  factoryBuilding: "Factory building",
  regionAttribute: "Region Attributes",
  overhaul: "Overhaul",
  checkMaintenance: "Check maintenance contract",
  noAddressName: "Elevator number, address, project name etc.",
  eleRemark3: "2.Please select 'Hydraulic elevator' if the elevator variety is hydraulic passenger lift or hydraulic freight elevator.",
  forbidUnsuccessful: "Forbade failed",
  keyAccurate: "Please enter double quotes and content for accurate lookup, such as 'XXX'",
  eleRemark2: "1.Please select 'Elevator' if the elevator variety is one of traction drive passenger lift, traction drive freight elevator, positive drive freight elevator and dumb waiter.",
  noCompanyOrTopLevel: "No matching company found or the company does not create a top level group.",
  eleRemark1: "The option above is the type of work order template, the specific type of elevator is based on the varieties of elevator information.",
  materialNo: "Material Number",
  verificationCodeIs: "Your verification code required",
  qrFormat: "Location of QR code format: 2 to 4 Chinese characters or 2 to 6 letters, numbers combinations",
  forbid: "Disabled",
  personnelActivityReport: "Personnel activity report",
  REFUSE: "Rejected",
  malfunctionState: "Malfunction status",
  ineffectiveHoursAnalysis: "Ineffective hours analysis",
  firstPageText: "Home",
  general: "Moderate",
  halfMonthM: "Semi-monthly",
  usedUnitNoAlreadyExists: "Building user No. already exists",
  parallel: "Parallel",
  annualStatistics: "Annual statistics of work order type",
  registrationCode: "Registration code",
  hospital: "Hospital",
  dailyOnDutyTime: "Daily on duty timing",
  errorSelectNode: "Please select the correct operation node",
  temp: "Temperature",
  renewalContent: "Renewal Content",
  percentageFiveStar: "Percentage of satisfaction (5 stars)",
  deviceCode: "Device code",
  positiveDrive: "Positive drive freight elevator",
  totalUnits: "Total number",
  durationNotRight: "Contract duration is incorrect",
  noPersonFind: "No maintenance personnel available, will expand the scope of your search.",
  unfinishedMO: "Unfinished MO",
  needInvoice: "To summit invoiced",
  enterRightPrice: "Please fill in the correct price, format:0.00",
  unqualifiedQDMC: "Unqualified QDMC",
  paymentInfo: "Payment Info",
  dec: "Dec.",
  caseOne: "Case one",
  amountCollected: "Amount collected($SGD)",
  malfunctionConfirmation: "Malfunction confirmation",
  newPwd: "New password",
  hourLineOnDuty: "24-hour hot-line",
  remainingDays: "Days remaining",
  maintenanceLevel: "Maintenance rating",
  codeIncorrect: "The SMS check code is incorrect",
  onlineEmployees: "Online employees",
  illegalCharactersInKunNo: "Illegal characters in Kunshan 119 No",
  sendOrder: "Send order",
  regionIOT: "IoT platform data report(region)",
  doYouWantToAddParentElevators: "Do you want to add elevators of parent group?",
  liNu: "License number",
  sensorType: "Sensor type",
  humidity: "Humidity",
  doorOpenOutput: "Door open output",
  manualQuotation: "Manual Quotation",
  workingHours: "Designated working hours",
  fillInCode: "Fill in the mobile phone check code ",
  elevatorList: "Elevator list",
  morePhone: "More than one user uses the phone no",
  queryCompany: "Query installation company",
  mild: "Satisfied",
  dailyStandbyTime: "Daily standby timing",
  personal: "Personal",
  upDown: "Up/Down",
  canceled: "canceled",
  repairOrderTotal: "Total number of repair work order",
  driveMode: "Drive mode",
  messagePush: "Urgent message(SMS)",
  route: "Route",
  installationManagement: "Installation contract management",
  totalVehicleMilesTravelM: "The total mileage of the vehicle(maintenance)",
  roleName: "Role name",
  currentFloor: "Current floor",
  hoistingHeight: "Hoisting height",
  average: "Average",
  billNumber:"billNumber",
  editCollector: "Edit collector",
  needQR: "You need to add new QR codes.",
  elevatorNoAlreadyExists: "Elevator no already exists",
  fillfailedInspectReason: "Please fill in the reason for the failed inspection",
  maintenanceStaffIOT: "IoT platform data report(maintenance staff)",
  paymentDateIsNotRight: "Date of payment is not right",
  forbidInfo: "Forbade successfully",
  totalRecordsAfterText: " records",
  rescueTO: "Average rescue time of TO(min)",
  auditConfirmation: "Audit confirmation",
  warningType:'warningType',
  numberOfElevator: "Number of elevators",
  sea: "search",
  emergencyRescue: "Emergency rescue",
  newStaff: "New maintenance staff",
  newInstallStaff: "New install staff",
  search: "Search",
  sNOne: "Sale outlet one",
  contractsExpiring: "Expiring contracts list",
  codeSignalGreater: "The QR code signal must be a valid integer greater than 0",
  onlineMaintenance: "Maintenance",
  hotel: "Hotel",
  deadline: "Deadline",
  obtainOrderPlan: "Obtain work order plan",
  roleAuthorization: "Role authorization",
  companyManagement: "Company management",
  sep: "Sep.",
  propertyCompany: "Property company",
  enterCompanyType: "Please enter company type",
  escalator: "Escalator",
  halfMonthUnits: "Total number of Semi-monthly maintenance",
  business: "Business",
  fillBrandName: "Please fill in brand name",
  column: "columns",
  sellingS: "Sales service ",
  elevatorStoppedOrNot: "Elevator stopped(Yes/No)",
  abnormalDescription: "Abnormal description",
  tagNo: "Tag number",
  remould: "Remould",
  ownerNickname: "Owner nickname",
  averageMaintenanceTimesByUnit: "Average maintenance times by unit",
  resetPwd: "Reset password",
  maintenanceContractManagement: "Maintenance contract management",
  maximum: "Maximum",
  aging: "Aging(days)",
  completionStatus: "Completion Status",
  freeToPaidMaintenance: "Free to paid-maintenance",
  newBillingRecord: "New billing record",
  quarter: "Quarterly maintenance",
  goodOrderTotal: "Total number of good work order",
  jul: "Jul.",
  equipmentNoAlreadyExists: "Equipment no already exists",
  jun: "Jun.",
  dispatch: "Dispatched",
  orderNumber: "Work Order Number",
  codeLocExist: "The location of QR code name already exists.",
  overIns: "Overdue self-inspection",
  language: "en-gb",
  liftOperation: "Elevator real-time running status",
  averageRepair: "Average repair(min)",
  equipmentContractNo: "Equipment No/Contract No",
  breakdownReasonChart: "Breakdown reason analysis chart",
  allOperation: "Overall operation",
  allEmployees: "All employees",
  elevatorExport: "Elevator export",
  generateBatch: "Generate batch",
  totalUnqualifiedQDMC: "Total Failure number for QDMC",
  yearEndM: "Annual",
  enterCorrectNumber: "Please fill in correct number",
  enterRightPaymentAmount: "Please enter right payment amount,format is 0.01",
  noEvaluation: "Not evaluated",
  secondFaultLevel: "Second fault level",
  subcontracting: "Whether it is subcontracted",
  newCollector: "New collector",
  oldNot: "Old password is incorrect",
  propertyContract: "Property contact person",
  queryContract: "Query installation contract",
  collectorManage: "Collector management",
  hoursMRO: "Total hours of maintenance and repair orders",
  otherPremises: "Other premises",
  publicBuilding: "Public building",
  joyIOT: "Joylive Elevator IoT System",
  monarchSystem: "Monarch system",
  escalatorSystem: "escalator system",
  generalSystem: "General system",
  stepSystem: "Step system",
  liftParameters: "Lift parameters",
  month: "Month",
  aliasAlreadyExists: "Alias already exist",
  fillInAI: "Please fill in annual inspection",
  dailyCompleteOrderNumbers: "Completed daily work order numbers",
  selectGroupLevel: "Please select group level",
  partialOperation: "Partial operation",
  selectPerson: "Please select maintenance staff",
  invoicedAmount: "Amount invoiced($SGD)",
  back: "Back",
  addedSuccessfully: "Added successfully",
  gopageBeforeText: "&nbsp;Go to",
  showRecordsFrom: "Showing",
  content: "Content",
  doYouWantToForbid: "Do you really want to disable it?",
  expiringContractUnits: "Expiring contract number",
  resetFailed: "Reset password failed",
  resetSuccess: "Reset password success",
  ignore: "Ignore",
  company: "Company",
  parentGroup: "Parent group name",
  trappedOrderTotal: "Total number of trapped work order",
  unfinished: "Unfinished",
  otherLogo: "Other logo",
  callInCar: "Call in car",
  statisticsInfo: "Analyze quantity of work orders, type, units, maintenance personnel information and other data by chart.",
  enterEffectiveDate: "Please fill in the date of validity of the contract",
  pendingIssues: "Pending issues",
  maintenanceConfiguration: "Maintenance configuration",
  notice: "Notice",
  projectAlias: "Place of use",
  theNumberOfTimesToDoNextHalfMonth: "What is the number of times to do next semimonthly maintenance",
  illegalCharactersInElevatorNo: "Illegal characters in elevator number",
  singleElevatorUpkeepCost: "Single maintenance fee(year)",
  durationGreaterThanZero: "Contract duration(monthly) to be greater than zero",
  repairTotalUnits: "Total number of Repair",
  trappedOrNot: "Man trap case(Yes/No)",
  aboveInfo: "The above information is provided by",
  permission: "Authority",
  systemRunningTime: "System running time",
  userName: "Username",
  companyWeb: "Company website",
  pleaseFillPartyA: "Please fill in Party A company",
  pleaseFillPartyB: "Please fill in Party B company",
  qualifiedRateSMC: "Total passing rate for SMC",
  businessCoop: "Joylive business cooperation",
  resultMore: "There are plenty of results, please enter accurate search criteria",
  pleaseSelect: "province/city/region",
  phoneNumberIncorrect: "The phone number format is incorrect",
  signInAgain: "Please sign in again",
  emptySearch: "Please choose at least one condition!",
  selectElevators: "Please select the elevators",
  generalFault: "General fault",
  itemContent: "Item content",
  select: "Please select",
  showRecordsToRecords: "",
  userNameEx: "Username already exists.",
  companyName: "Company name",
  consigneeNo: "Consignee Contact Number",
  pFive: "Product five",
  repairUnits: "Repair units",
  qrCodeToll: "QR Code",
  trappedOrder: "Trapped work order",
  qrCodeGenerator: "QR Code Generator",
  quotationNo: "Quotation Number",
  payee: "Payee",
  accountsReceivable: "Accounts receivable($SGD)",
  verySatisfied: "Very satisfied",
  alreadyInspect: "Already inspect",
  staffInspectsData: "Staff assessment data",
  getCode: "Get SMS check code",
  showRecordsTo: " to ",
  loadingData: "Loading,please wait... ",
  oct: "Oct.",
  transferUnits: "Transfer units",
  fro: "From",
  timeOfFault: "Time of failure",
  confirmNewPwd: "Confirm new password",
  chartAnalysis: "Chart analysis",
  malfunctionStatistics: "Statistics of malfunction times",
  orderExport: "Work order export",
  actualCompletionNumber: "Actual completed number",
  maintenancePerformed: "Maintenance performed",
  applyAdvancedUser: "Apply for advanced user",
  fillInRec: "Please upload rectify order",
  maintenancePlanHasImported: "This maintenance program has been imported for safeguard",
  tractionPassenger: "Traction drive passenger lift",
  howManyDayVerify: "The number of days required to complete should be between 1-5",
  certification: "Certification",
  noRecord: "No matching records found",
  selectMenu: "Please select the previous menu of this function",
  aiUpdate: "Annual Inspection Update",
  enterExpiryDate: "Please fill in the contract due date",
  notApproved: "Not approved",
  trappedUnits: "Trapped units",
  deleteGroupNo: "You has no authority to delete top level group",
  currentMonthMaintenanceTimes: "Maintenance times in this month",
  status: "Status",
  trappedRate: "Trapped rate",
  onStandby: "On standby",
  invoiceParty: "Invoice Party",
  onlineInstallationInfo: "Online installation management",
  saveAll: "Save all",
  clientName: "Client Name",
  totalPrice: "Total Price",
  signingDate: "Contract date",
  caseFour: "Case four",
  contactPerson: "Contact person",
  errorInfo: "Oops,page not found.",
  validateBeaconNo: "Validate beacon no",
  mainUnits: "Maintenance number",
  trappedTimes: "Number of trapped",
  maintenanceName: "Maintenance name",
  malfunction: "Malfunction",
  newRenewalContract: "New renewal contract",
  qualityReport: "Quality report",
  malfunctionHandling: "Urgent assignment ",
  poorOrderTotal: "Total number of poor work order",
  repairOrder: "Repair work order",
  statusSelection: "Status selection",
  inspectResult: "Inspect result",
  man: "Manager",
  componentReason: "Component reason",
  groupNameHas: "The company already has the same group name in the same grade",
  malfunctionLifts: "Failure of the elevator, according to the status fault, emergency and fault type, will be indicated with different symbols indication.",
  mar: "Mar.",
  controlMode: "Control mode",
  repairTimes: "Repair times",
  nineNoAlreadyExists: "96333 No. already exists",
  planType: "Plan type",
  may: "May",
  staffData: "Employee data",
  elevatorManagement: "Elevator management",
  researchDebugging: "R&D Debugging",
  suggestion: "Suggestion",
  cylinderNum: "Cylinder numbers",
  fillInCer: "Please fill in certification ",
  jackingType: "Jacking type",
  overhaulBilling: "Overhaul billing",
  myFavorite: "My favorite",
  userNameEm: "Username can not be empty.",
  totalUnqualifiedNEMC: "The total number of new elevator that Fail the (NEMC) check",
  codeLocation: "Location of QR code",
  doorClose: "Door close",
  eventStatus: "Event status",
  hydraulicPassenger: "Hydraulic passenger lift",
  report: "Report",
  ifYouCanNotFindIt: "If you can not find it, try it here",
  nextPageText: "Next page",
  conscious: "Conscious",
  mapLocation: "Map location",
  contractManagement: "Contract management",
  unitsSMCIn: "Total number of SMC in a department",
  chooseTakePictures: "Please choose whether to take pictures",
  addedFailed: "Added failed",
  contractRemainingDays: "Contract days remaining",
  partsBilling: "Replacements billing",
  download: "download",
  high: "High",
  monthlyPayment: "Monthly payment",
  host: "Host",
  staffMovement: "Staff movements",
  invoiceNo: "Invoice No.",
  invoiceType: "Invoice Type",
  amountMO: "Amount of MO",
  serVio: "serious violation",
  enterElevatorNo: "Please enter elevator no",
  orCode: "Organization code",
  maintenanceOrderNumbers: "Completed maintenance work order numbers ",
  level: "Level",
  passwordEm: "Password can not be empty.",
  brandExists: "Elevator brand already exists",
  doYouWantToUpdate: "Do you want to update?",
  qualifiedSMC: "Total passing number for SMC",
  totalQualifiedQDMC: "Total Passing number for QDMC",
  operationTime: "Operating time",
  stateDetection: "State detection ",
  billingDate: "Billing date",
  installationCompany: "Installation company",
  maintenancePlan: "Maintenance plan",
  allActions: "Monitoring elevator positioning and operational states during operation,data collected on real-time performance. Synchronous display in animation",
  allCategories: "All Categories",
  cityPlaceHolder: "For example: XX Road,XX County/District,XX City,XX Province",
  repairCompany: "Renovation and major repair company",
  updateInspectStatus: "Update Inspection Status",
  updatedSuccessfully: "Updated successfully",
  tractionSteel: "Traction steel home lift",
  fillLocation: "Please fill in location of QR code",
  no: "No",
  overdueReason: "Overdue reason",
  buttonTipAfterText: "",
  searchCriteria: "Search criteria",
  fillContractNo: "Please fill in the maintenance contract number",
  workHours: "On duty hours",
  problemSolvingTroubleshooting: "Problem solving and troubleshooting",
  ratedSpeed: "Rated speed",
  recordWrong: ":The value is wrong, please check the billing record.",
  allLose: "Overall lose",
  totalVehicleMilesTravelT: "The total mileage of the vehicle(trapped)",
  delete: "Delete",
  fillInOldStaff: "Please fill in former maintenance staff",
  fillInStartTime: "Please fill in start time or end date",
  total: "Total number",
  hydraulicFreight: "Hydraulic freight elevator",
  freeMaintenanceUnits: "Free-maintenance number",
  balancePayment: "Outstanding balance payment",
  usedUnitNo: "Building user no",
  salesOrderNo: "Sales Order Number",
  dearUser: "Dear user, welcome to use our product",
  handover: "Work handover",
  lostType: "Lost type",
  CollectedAmountGreaterThan: "Amount collected should be greater than 0 and format is 0.00.",
  viewScheduler: "Maintenance plan",
  averageMaintenanceTimeConsumingByUnit: "Average maintenance time-consuming by unit(hour)",
  changePassword: "Change password",
  otherStatus: "Other status",
  repairTotalHours: "Repair total hours(hour)",
  unitNoAlreadyExists: "User Unit number already exist",
  deliveryOrderNo: "Delivery Order Number",
  processed: "Processed",
  takePictures: "Take pictures",
  maintenanceRepair: "Maintenance/repair",
  allLost: "Overall lost",
  online: "Online",
  completionDate: "Completed time",
  inspectReasonChart: "Falied inspection analysis chart",
  orderCategory: "Work order category",
  accountAmount: "Account amount ",
  renewRecord: "Renew record",
  handler: "Handler",
  InstallationSupervision:"InstallationSupervision",
  trappedUntreated: "Unattended assignment",
  currentYearBillingRevenue: "Annual invoicing($SGD)",
  overloadSignal: "Overload signal",
  aRescueTO: "Average rescue time of TO(min)",
  idCard: "ID card",
  userNotExist: "User does not exist",
  faultLevel: "Fault level",
  realTimeStatus: "Real-time status",
  currPageBeforeText: "Current At Page&nbsp;",
  province: "Province",
  productConsultation: "Product consultation",
  selfMaintenanceUnitsPaid: "Self maintenance units(paid)",
  stateless: "No status",
  lastModifyDate: "Updated date",
  anIn: "Annual Inspection",
  selectMaintenanceStaff: "Select maintenance staff",
  inputInfo: "Information input entry",
  inspectFailed: "Failed to update inspection status",
  usernameDisabled: "Username is disabled",
  edit: "Edit",
  entrustUnits: "Entrust units",
  hydraulicLadder: "Hydraulic elevator",
  mall: "Mall",
  enterStaffAccount: "Please enter staff account number",
  equipmentNo: "Platform file number",
  partialLost: "Partial lost",
  workOrderProcess: "Work order processing",
  machineRoomIntrusion: "Machine room intrusion",
  submitItemTips: "Please submit or cancel unsaved content",
  inServiceTime: "In service time",
  maintenanceAccount: "Maintenance account management",
  BillingDateIsNotRight: "Billing date is incorrect",
  receiptInfo: "Receipt info",
  kunNoAlreadyExists: "Kunshan 119 No. already exists",
  unitsQDMC: "Total units of QDMC",
  paidAmount: "Amount paid($SGD)",
  baseInfo: "Basic information",
  phoneEx: "Telephone already exists.",
  maintenancePerson: "Maintenance staff",
  contractDuration: "Contract duration(month)",
  noContractdata: "No relevant contract data, please input again",
  hideShowPagination: "Hide/show pages",
  latitude: "Latitude",
  partsPerformed: "Replacements performed",
  distanceTimeConsumingForUrgentRepair: "Travel duration for urgent repair(hour)",
  maintenanceContractPerson: "Maintenance contact person",
  quarterlyUnits: "Total number of Quarterly maintenance",
  offline: "Offline",
  sendTrappedOrder: "Distribute trapped case work orders",
  current: "Current",
  enterPartyAB: "Please enter Party A and Party B",
  duePaidMaintenance: "Due paid-maintenance",
  anySearch: "Search inquiry",
  overdue: "Overdue",
  renewManage: "Renew management",
  partialLose: "Part lose",
  free: "Free maintenance(FOC)",
  email: "E-mail",
  productNo: "Product no",
  phoneNotEmpty: "Please fill in phone No",
  usernameUidNotExist: "Invalid username or ID does not exist",
  controller: "System controller",
  fillInNewStaff: "Please fill in new maintenance staff",
  middle: "middle",
  userDoesNotExist: "The user does not exist",
  manager: "Maintenance manager",
  manMachineInteraction: "Interactive man-machine",
  noticeTips: "If you want to search work orders during a period, start time should be before deadline",
  blacklist: "Blacklist Announcement",
  manufacturingCompany: "Manufacturing company",
  sendMessage: "Urgent message sent successfully",
  enterRightAmount: "Please fill in the correct contract amount, format:0.00",
  numberSatisfactionEvaluation: "Total number of satisfaction evaluations",
  started: "Started",
  statisticalStartDate: "Statistical start date",
  newReceiptInfo: "New receipt record",
  maintenancePhone: "Maintenance phone",
  systemSetting: "System program",
  confirm: "Confirm",
  connected: "Connected to the system",
  satisfactionFive: "Satisfaction(Out of 5)",
  adverseType: "Adverse type",
  groupWeb: "Group official website",
  to: "to",
  disNotEmpty: "Display floor number can not be empty.",
  operation: "Operation",
  officeBuilding: "Office building",
  repairTimesByUnit: "Repair times by unit",
  agent: "Agent",
  namePassWrong: "Incorrect username or password. Please try again.",
  equipmentNoShouldBeSix: "The equipment No. should be six-figure number",
  invoiceAmount: "Invoice amount($SGD)",
  completeRO: "Complete RO",
  sentMessageSuccessfully: "Message sent successfully",
  expectedReceivableAgeAnalysis: "Expected receivable age analysis",
  initiateMaintenance: "Initiate maintenance",
  quarterlyPayment: "Quarterly payment",
  createdOrderFailed: "Failed to create work order",
  collectionDays: "Collection date",
  sectionLength: "Section length",
  dailyMaintenance: "Daily maintenance management",
  moreThan3Months: "More than 3 months",
  formerStaff: "Former maintenance staff",
  formerInstallStaff: "Former install staff",
  details: "Details",
  selectMaintenanceType: "Please select maintenance type",
  outTerminalState: "Main control board output terminal status",
  totalTrappedTimes: "Total number of trapped people",
  floorsInfo: "Floors Information",
  companyNameAlreadyExists: "The company name already exists",
  maintenanceBilling: "Maintenance billing",
  query: "Inquiry",
  billingInformation: "Billing information",
  disFloor: "Display floor",
  partyA: "Party A company",
  referenceRange: "Reference range",
  doorLockSignal: "Door lock signal",
  failedToGetData: "Failed to receive data",
  failure: "Failed",
  notSynchronized: "Not synchronized",
  partyB: "Party B company",
  enterEquipmentNo: "Please enter equipment no",
  inspect: "Inspect",
  regulator: "Regulator",
  unfinishedCFO: "Unfinished CFO",
  region: "Region",
  updatedUnsuccessful: "Updated failed",
  eleInfo: "Elevator information",
  lostDueFreeMaintenance: "Lost due free-maintenance",
  requestExpired: "request has expired",
  records: "records",
  notConnected: "Not connected to the system",
  completeTO: "Complete TO",
  update: "Update",
  repairRecord: "Repair record",
  onDuty: "On duty",
  averageMaintenanceUnitsByStaff: "Average maintenance units by staff",
  inTerminalState: "Main control board input terminal status",
  onlineInstallation: "Installation",
  orderNumbers: "Total number of work order",
  unknownState: "The status is unknown",
  ownerName: "Owner name",
  roleNameCanNotEmpty: "Role name can not be empty",
  halfAYearUnits: "Total number of Semi-yearly maintenance",
  recordOperational: "Record operational process",
  selectRole: "Please select organization role",
  terminalOpenClose: "Terminal open and close",
  stationNot: "Please fill in right layer/station/door,format:10/10/10",
  callCenter: "Call center",
  inspectStatistic: "Statistic of bill inspection",
  normal: "Normal",
  photoEditor: "Photo editor",
  rescueTimeConsuming: "Rescue time-consuming(hour/min)",
  doYouWantToDelete: "Do you really want to delete?",
  voltage: "Voltage",
  doNotRevealToOthers: "To be advised not to reveal to others",
  collectiveSelective: "Collective selective",
  doYouWantToAdd: "Do you want to add?",
  fillInStaff: "Please fill in maintenance staff",
  mainPower: "Main power",
  inadequateAdjustment: "Inadequate adjustment",
  location: "Location",
  stationFormat: "format:10/10/10",
  noInspect: "No inspect",
  paymentDate: "Date of payment",
  fileType: "File type",
  fileSize: "File Size",
  fileInfo: "File infomation",
  templateType: "Template type",
  userInfo: "User info",
  nodeOne: "Section point one",
  endDate: "End time",
  contractType: "Contract Type",
  protocolOfBrand: "Brand certification plate",
  none: "None",
  useOccasion: "Occasionally use",
  signUp: "Register",
  caseThree: "Case three",
  levelInfo: "The company does not have elevator brands information, you can not add the maintenance level.",
  yearEndUnits: "Total number of Annual maintenance",
  elevator: "Elevator",
  supportStaff: "Support staff",
  groupLevel: "Group level",
  allRenew: "Overall renewal",
  receivedDate: "Received Date",
  checkOrder: "Check work order",
  untreated: "Unhandled",
  ignored: "Ignored",
  newFreeMaintenance: "New free-maintenance",
  openDoorDelayBtn: "Door open delay button",
  completeMO: "Complete MO",
  companyTEL: "Company TEL",
  downCall: "Down call",
  lumpSumPayment: "Lump sum payment",
  deleteGroupCanNot: "You can not delete the group which already has been used",
  caseStudy: "Case study",
  semiyearlyPayment: "Semiyearly payment",
  contractLost: "The contract has been lost",
  rapidRescue: "Rapid internet rescue service",
  buttonTipBeforeText: "Page",
  caseFive: "Case five",
  selectUrgentStaff: "Select an urgent maintenance staff ",
  contractSource: "Contract source",
  totalNEMC: "Total units of new elevator maintenance check(NEMC)",
  notStarted: "Has not start",
  repairRO: "Average repair time of RO(min)",
  lastModifier: "Updated person",
  layerStationDoor: "Layer/station/door",
  unitsStatistics: "Units statistics",
  phoneNo: "Phone No",
  basicInfo: "Basic information",
  nick: "name",
  totalUnqualifiedSMC: "Total unqualified of SMC",
  anException: "There is an exceptional",
  event: "Event",
  maintenanceTimes: "Maintenance times",
  totalUnitsNEMC: "Total units of NEMC",
  staffAccountExists: "Staff account already exists",
  sTimeBeforeEndTime: "Start time should be before end time",
  createDate: "Creation date",
  trappedOrderNumbers: "Completed trapped work order numbers",
  qualifiedRateSMCIn: "Qualified rate for SMC in a department",
  contractStatus: "Contract status",
  fillSubcontractCompany: "Please fill in the subcontracting company",
  typicalCase: "Typical Case",
  currentStatus: "Current state",
  responseTime: "Response time",
  materialQuotation: "Quotation for Material",
  sendLater: "To be sent later(update)",
  toggle: "Toggle ",
  otherYear: "Other years",
  PENDING: "Pending",
  caseNo: "Case No",
  workBillInfo: "Work order information",
  trappedAlarm: "Emergency alarm activation",
  regulations: "Regulations",
  WAITING: "To be accepted",
  faultDescription: "Fault description",
  runningState: "Operating status",
  gridRescue: "Grid Rescue",
  connecting: "Connecting...",
  uploadAttachment: "Upload Attachment",
  fillUpdateContent: "Please fill in update content",
  minimum: "Minimum",
  bstSystem: "BST system",
  statusNormal: "Elevator status: Normal ",
  date: "Date",
  averageMaintenanceTimesByStaff: "Average maintenance times by staff(hour)",
  performanceEvaluation: "Performance evaluation",
  abnormalCo: "Abnormal communication",
  checkYourAPP: "In case of trapped events, please keep an eye on your dispatch system.",
  userLock: "The current user is locked.",
  spec: "Specification",
  generalO: "General",
  maintenanceOrder: "Maintenance work orders",
  MondayFri: "From Monday to Friday",
  from: "from",
  brandNo: "Brand no",
  permissionNotExist: "Not authorised",
  unaudited: "Unaudited",
  audited: "Audited",
  totalQualifiedSMCRate: "Total pass rate of SMC",
  brandName: "Brand name",
  malfunctionConRecord: "Malfunction confirmation record",
  nineNo: "96333 number",
  eleFaultChart: "Elevator fault condition chart",
  rejectingTimes: "Total number of rejection",
  unmaintained: "Unmaintained",
  finished: "Finished",
  timespan: "Timespan",
  beaconNoAlreadyExists: "Beacon no already exists",
  reassignFailed: "Failed to reassign work order",
  SEND: "To be distributed",
  contractPeriod: "Contract period(year)",
  factoryDefault: "Factory default",
  serviceCall: "Service call",
  faultTimesIgnored: "Number of elevator failures",
  person: "Staff",
  sendImmediately: "To be sent immediately",
  reconditionState: "Recondition status",
  repair: "Repair",
  terminalState: "Terminal state",
  userManagement: "User management",
  roleDoesNotExist: "The role does not exist",
  anInSchedule: "Annual Inspection Schedule",
  batchUpdate: "Batch update",
  videoMonitor3: "3G Video monitor",
  videoMonitor4: "4G Video monitor",
  buildingUsingParty: "Building using party",
  malfunctionIndication: "Fault and malfunction indication",
  totalAmountTO: "Total amount of trapped orders(TO)",
  paymentAmount: "Payment amount",
  newContract: "New contract",
  passwordDiffer: "The passwords do not match, please try again.",
  car: "Car",
  inUse: "In use",
  station: "Maintenance station",
  elevatorType: "Elevator varieties",
  quotationPrice: "Quotation Price",
  countdownExpiryDate: "The contract expires date",
  value: "Value",
  stationBuilding: "Station",
  qualifiedRateQDMC: "Qualified rate of QDMC",
  pOne: "Product one",
  trappedTotalUnits: "Total number of Trapped",
  finishedOrders: "Finished work order",
  moduleType: "Module type",
  difStaff: "New maintenance staff must be different from former maintenance staff.",
  doYouWantToImport: "Do you want to import the excel plan in C disk?",
  supportedTech: "Supported Technologies",
  INHAND: "Processing",
  juliElevator: "Joylive Elevator Limited Company",
  elevatorsData: "Elevators data",
  closeDoorBtnShow: "Door close button show",
  doYouWantToSelectAll: "Do you want to select all and add all?",
  noLatLng: "The elevator not coordinates.",
  moreThan2Years: "More than 2 years",
  topLevelFirst: "Please add the top level group first",
  appFunction: "APP function",
  pFour: "Product four",
  governmentBuilding: "Government building",




  newWorkOrder: "New Work Order",
  viewWorkOrder: "Check the Work Order",
  input: "Please enter",
  enable: "enable",
  filter: "filter",
  managerLevel2: "User management level 2",
  managerLevel3: "User management level 3",
  addUser: "Add user",
  banUser: "Ban user",
  editUser: "Edit user",
  qrCodeTool: "Qr code tool",
  addBlacklist: "Add blacklist",
  editBlacklist: "Edit blacklist",
  deleteBlacklist: "Delete blacklist",
  addExposureEvent: "Add exposure event",
  editExposureEvent: "Edit exposure event",
  deleteExporeEvent: "Delete expore event",
  APPMaintenanceModule: "App maintenance module",
  APPLiveDataModule: "APP live data module",
  APPVideoMonitorModule: "APP video monitor module",
  APPDataCalculationModule: "APP data calculation module",
  staffLocation: "Employee location",
  workOrderCalculation: "Work order calculation",
  elevatorLocatioin: "Elevator locatioin",
  newElevatorBrand: "New elevator brand",
  removeElevatorBrand: "Delete elevator brand",
  deleteCollector: "Delete collector",
  onlineMonitoringLevel2: "Monitoring level 2",
  test: "Test",
  productDistributionSplitScreen: "Product distribution split screen",
  checkGridRescue: "Check grid rescue",
  addRepairRecord: "Add repair record",
  editRepairRecord: "Edit repair record",
  deleteRepairRecord: "Delete repair record",
  annualInspectorLevel2: "Annual Inspection level 2",
  annualInspectorLevel3: "Annual Inspection level 3",
  annualInspectionDetail: "Annual Inspection detail",
  addMaintenanceStaff: "Add maintenance staff",
  deleteMaintenanceStaff: "Delete maintenance staff",
  updateMaintenanceStaff: "Update maintenance staff",
  serviceCenterrReport: "Service center report",
  serviceLife_BreakDownTimeRelation: "serviceLife_BreakDownTimeRelation",
  breakDownAnalyze: "Breakdown analyze",
  addMaintenanceItem: "Add maintenance item",
  editMaintenanceItem: "Edit maintenance item",
  deleteMaintenanceItem: "Delete maintenance item",
  maintenancePlan2: "Maintenance plan",
  editBillingRecord: "Edit billing record",
  deleteBillingRecord: "Delete billing record",
  exportBillingRecord: "Export billing record",
  editReceiptRecord: "Edit receipt record",
  deleteReceiptRecord: "Delete receipt record",
  exportReceiptRecord: "Export receipt record",
  addLog: "Add Log",
  deleteLog: "Delete Log",
  exportData: "Export Data",
  syncData: "Synchronous Data",
  elevatorImport: "Elevator import",
  floorsInfoManagement: "Floors information management",
  removeElevator: "Delete elevator",
  editElevator: "Edit elevator",
  addCompany: "Add company",
  deleteCompany: "Delete company",
  editCompany: "Edit company",
  contractImport: "Contract import",
  exportFinanceData: "Export finance data",
  exportContract: "Export contract",
  editMaintenanceContract: "Edit maintenance contract",
  deleteMaintenanceContract: "Delete maintenance contract",
  viewMaintenanceContract: "View maintenance contract",
  addGroup: "Add group",
  editGroup: "Edit group",
  deleteGroup: "Delete group",
  editingAuditRescuersInfo: "Editing audit rescuers information",
  breakdownReasonAnalysis: "Breakdown reason analysis",
  historicalRoute: "Historical route",
  addWorkOrder: "Add work order",
  workOrderInspect: "Work order inspect",
  deleteWorkOrder: "Delete work order",
  exportPresentation: "Export presentation",
  exportReport: "Export report",
  exportPendingMatters: "Export pending matters",
  deleteMaintenanceBatch: "Delete maintenance batch",
  exportMaintenanceProgram: "Export maintenance program",
  addMaintenancePlan: "Add maintenance plan",
  editMaintenancePlan: "Edit maintenance plan",
  deleteMaintenancePlan: "Delete maintenance plan",
  viewMaintenanceSchedule: "View maintenance schedule",
  addRoles: "Add role",
  editRole: "Edit role",
  disableRole: "Disable role",
  originalcontract: "Original contract",
  maintenancePlanInspect: "Maintenance plan inspect",
  EXAMINED: "examined",
  custom: "custom",
  Reason: "Reason",
  maintainstatus: "Maintenance Status",
  confirmsubmission: "Confirm submission",
  faultSetting: "Fault push setting",
  elevatorPushable:"Elevator fault push setting",
  faultpushstatusfunction: "Fault push status function",
  faultpush: "Fault push",
  pushon: "Push on",
  pushclosed: "Push closed",
  operationRecord: "operation Record",
  reportingtime: "Reporting time",
  abbreviation: "abbreviation",
  pushMessage: "Push Message",
  menuPermissions: "Menu permissions",
  functionitempermission: "Function item permission",
  inspectResponseStatic: "Statistic of bill response inspection",
  basicNodeConfig: "Basic node library configuration",
  newNode: "New node",
  nodeLibraryDeploy: "Node Library Deploy",
  nodeContent: "Node content",
  inExecution: "In execution",
  dateOfSigning: "Date of signing",
  contacts: "contacts",
  representativeA: "Representative of Party A",
  representativeB: "Representative of Party B",
  addressA: "Address of Party A", 
  addressB: "Address of Party B",
  telephoneA: "Telephone of Party A",
  telephoneB: "Telephone of Party B",
  contractDetails: "Installation contract details",
  projectAddress: "Project address",
  nodeTemplate: "Node template",
  elevatorInstall1: "Online installation",
  installOperationNode: "Node template library",
  installWorkOrder: "Installation work order management",
  installOrderDetail: "Installation work order details",
  auditor: "auditor",
  newAuditor: "newAuditor",
  oldAuditor: "oldAuditor",
  addInstallWorkOrder: "Add installation work order",
  deleteInstallWorkOrder: "Delete installation work order",
  editInstallWorkOrder: "Edit installation work order",
  elevatorInstall:"Installation",
  mapView: "Engineer Distribution",
  addNodeLibrary:"Add node library",
  deleteNodeLibrary:"Delete node library",
  editNodeLibrary:"Edit node library",
  APPInstallModule: "APP Install Module",
  auditInstallWorkOrder: "Audit installation work order",
  addInstallContract: "Add installation Contract",
  editInstallContract: "Edit installation Contract",
  deleteInstallContract: "Delete installation Contract",
  editRegionAttribute: "edit region attribute",
  deleteRegionAttribute: "delete region attribute",
  addRegionAttribute: "add region attribute",
  noneFunction: "This function has not been activated yet",
  auditOpinion: "Audit opinion",
  adopt: "adopt",
  Failed1: "Failed",
  width: "width",
  height: "height",
  qrCodeLength: "The length is between 1 and 10 characters",
  currentVoltage: "current/voltage",
  executive: "executive",
  receiver: "receiver",
  receivingMode: "receiving mode",
  elementId: "elementId",
  samedayWorkBillStatistics: "Same-day work bill statistics",
  dataOverview2: "dataOverview2",
  todayBillTotal: "total number of work orders today",
  currentFinishBill: "current number of completed work orders",
  timeOutBill: "number of work orders to be timed out",
  advertManage: "Advertising management",
  programManage: "Program management",
  matterList: "Material management",
  deviceManage: "Device management",
  programList: "Program list",
  createProgram: "Creating programs",
  packetProgram: "Package program",
  packetProgramList: "Package program list",
  playPlan: "Play schedule",
  deviceList: "Device list",
  audit: "audit",
  preview: "preview",
  releaseProgram: "Release program",
  device: "device",
  available: "available",
  unavailable: "Not available",
  toBeReviewed: "To be reviewed",
  createdDate: "createdDate",
  programDuration: "Program duration",
  timeSlot: "time slot",
  broadcastDate: "Broadcast date",
  homeScreen: "Home screen",
  secondaryScreen: "Secondary screen",
  playArea: "Play area",
  batchDelete: "Batch delete",
  batchAudit: "Batch audit",
  refuse: "refuse",
  programNotes: "Program notes",
  programName: "Program name",
  chooseProgram: "Please select the program first!",
  chooseMatter: "Please select the matter first!",
  previewAfterEdit: "You have not edited the program information, please edit it again and preview it!",
  deviceName: "Device name",
  deviceMAC: "Device MAC",
  deviceSN: "Device SN",
  organization: "Organization",
  deviceDirection: "Device direction",
  horizontalScreen: "Horizontal screen",
  verticalScreen: "vertical screen",
  programSize: "Program size",
  programDirection: "Program direction",
  directionAdaptation: "Direction adaptation",
  directionOnErrorTip: "The terminal direction adaptation you selected is not available, please select again!",
  picture: "picture",
  document: "document",
  turnOn: "Turn on",
  turnOff: "Turn off",
  volumn: "volumn",
  faceRecognition: "Face recognition",
  doubleScreenSwitch: "Double screen switch",
  workingHours: "working hours",
  programDetails: "Program details",
  unbundling: "Unbundling",
  exitPassword: "Exit password",
  deviceInfo: "Device information",
  bindingTime: "Binding time",
  registrationTime: "Registration time",
  IPAddress: "IP address",
  screenInformation: "Screen information",
  deviceMemory: "Device memory",
  deviceStorage: "Device storage",
  versionInformation: "Version information",
  firmwareVersion: "Firmware version",
  applicationVersion: "Application version",
  screenshot: "screenshot",
  standby: "Standby",
  awaken: "awaken",
  restart: "restart",
  adjustVolume: "Adjust the volume",
  emptyProgram: "Empty program",
  partsManagement: "Parts management",
  partsMaintenance: "Parts maintenance",
  queryOfProblemParts: "Query of problem parts",
  rescueWorkers: "Rescue workers",
  partName: "part name",
  technicalReplacement: "Technical conditions for replacement",
  replacementCycle: "Replacement cycle",
  monthly: "monthly",
  annually: "annually",
  remarks: "remarks",
  historicalUpdate: "Historical update",
  noHistoricalData: "No historical update data",
  updateDate: "Update date",
  ipcWarning:'Ipc Warning',
  updateReason: "Update reason",
  addPart: "Add part",
  selectPart: "Please select a part!",
  qualitySupervisionBureau: "Quality Supervision Bureau",
  batchElevatorParts: "Batch elevator parts management",
  EUCRN: "Equipment use certificate registration No",
  batchAudit:"BatchAudit",
  AuditSignature:"AuditSignature",
  elevatorIP:"elevatorIP",
  APPCustomModule:"APP Custom Module",
  debugOrNot:"debugOrNot",

}
/* eslint-disable no-new */